<template>
	<div class="FicheEntreprise pa-5">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<InfoCompany :company="company" :companyID="userData.company" v-if="userData.company" />

		<v-row class="mt-3">
			<v-col cols="12">
				<v-row>
					<v-card class="mx-auto">
						<v-col cols="12" sm="12" md="12" align="center">
							<h3 class="headline">Status</h3>
							<v-btn-toggle v-model="companyStatus" :color="companyStatus.color" group mandatory>
								<v-btn v-for="status in $listUtils.entityStatus" :key="status.label" :value="status"
									class="mx-0" @click="changeStatus(status)">
									{{ status.label }}
								</v-btn>
							</v-btn-toggle>
						</v-col>

						<v-col cols="12" sm="12" md="12" align="center">
							<h3 class="headline">Funnel</h3>
							<v-btn-toggle v-model="funnelCompanyStatus" :color="funnelCompanyStatus.color" group
								mandatory>
								<v-btn v-for="status in $listUtils.funnelEntityStatus" :key="status.label"
									:value="status" class="mx-0" @click="changeFunnelStatus(status)">
									{{ status.label }}
								</v-btn>
							</v-btn-toggle>
						</v-col>
					</v-card>
				</v-row>
			</v-col>


			<v-col>
				<div class="mb-2">
					<v-btn v-if="!edit" width="200" class="float-right neon" outlined color="primary"
						@click="edit = true">Modifier</v-btn>
					<v-btn v-else width="200" class="float-right neon" outlined color="success" @click="submit()">
						Sauvegarder</v-btn>
				</div>
			</v-col>

		</v-row>

		<Remarks
			v-if="infoLoaded"
			type="companies"
			:entity="company"
			:user="userData"
		/>

		<Mails
			v-if="infoLoaded && company.email"
			:entity="company"
			:user="userData"
		/>

		<InfoComplementary :data="company" v-bind:edit="edit" />

		<InfoBank :data="company" :tvaConcerned="true" v-bind:edit="edit" v-if="infoLoaded" />

		<DataTable title="Employés" type="customers" :company="userData.company" canDelete showExpand
			:headers="$tableHeaders.customers" :items="employees" itemKey="id" link="/record/customer" />

		<v-col>
			<div class="mb-2">
				<v-btn v-if="!edit" width="200" class="float-right neon" outlined color="primary" @click="edit = true">
					Modifier</v-btn>
				<v-btn v-else width="200" class="float-right neon" outlined color="success" @click="submit()">
					Sauvegarder</v-btn>
			</div>
		</v-col>

	</div>
</template>

<script>
	import Head from '@/components/graphics/head';

	import Remarks from '@/components/modules/@widgets/Remarks';
	import Mails from '@/components/modules/@widgets/Mails';
	/*import subscriptions from '@/components/modules/@widgets/subscriptions';
	import commands from '@/components/modules/@widgets/commands';
	import invoices from '@/components/modules/@widgets/invoices';
	import shippings from '@/components/modules/@widgets/shippings';*/

	import InfoComplementary from '@/components/modules/@widgets/InfoComplementary';
	import InfoBank from '@/components/modules/@widgets/InfoBank';

	import InfoCompany from '@/components/modules/@company/InfoCompany';

	import DataTable from '@/components/modules/#global/DataTable';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			Head,

			Remarks,
			Mails,
			DataTable,
			/*subscriptions,
			commands,
			invoices,
			shippings,*/
			InfoComplementary,
			InfoBank,

			InfoCompany
		},
		data() {
			return {
				company: this.$models.company,

				employees: [],

				userData: this.$models.user,

				edit: false,

				Head: {
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "20vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"
				},

				companyStatus: {
					color: 'primary'
				},

				funnelCompanyStatus: {
					color: 'primary'
				}
			}
		},

		computed: {
			infoLoaded: function () {
				if (this.company.id) return true;
				else return false;
			}
		},

		methods: {
			submit() {
				const newCpny = this.company;
				delete newCpny.id;
				delete newCpny.creationDate;
				delete newCpny.company;

				this.$db.collection("companies").doc(this.userData.company).collection("companies").doc(this.id).set({
					...newCpny
				}, {
					merge: true
				}).then(() => {
					this.edit = false;

					this.$store.dispatch('setSnackbar', {
						text: `${newCpny.name} - Entreprise n°${newCpny.nCompany} a été modifiée !`,
					});
				})
			},

			changeStatus: function (attr) {
				this.$db.collection("companies").doc(this.userData.company).collection("companies").doc(this.id).set({
					status: this.$listUtils.entityStatus.indexOf(attr)
				}, {
					merge: true
				}).then(() => {
					this.edit = false;
				});
			},

			changeFunnelStatus: function (attr) {
				this.$db.collection("companies").doc(this.userData.company).collection("companies").doc(this.id).set({
					funnel: this.$listUtils.funnelEntityStatus.indexOf(attr)
				}, {
					merge: true
				}).then(() => {
					this.edit = false;
				});
			},
		},

		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userData = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userData.company).collection("companies").doc(this.id)
					.onSnapshot(doc => {
						this.company = {
							...doc.data(),
							creationDate: this.$moment(),
							id: doc.id
						};

						this.companyStatus = this.$listUtils.entityStatus[this.company.status];
						this.funnelCompanyStatus = this.company.funnel ? this.$listUtils.funnelEntityStatus[
							this.company.funnel] : this.$listUtils.funnelEntityStatus[0];

						this.Head.Title = document.title =
							`${this.company.name} - Entreprise n°${this.company.nCompany}`;

						this.$db.collection("companies").doc(this.userData.company).collection("customers")
							.where("company", "==", this.company.id).onSnapshot((ref) => {
								this.employees = [];

								ref.forEach((employee) => {

									this.employees.push({
										...employee.data(),
										id: employee.id
									});

								});
							});
					});
			});
		}
	}

</script>
